import React from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { isThisSecond } from 'date-fns';
import DragSortableList from 'react-drag-sortable';

import CircularProgress from '@material-ui/core/CircularProgress';

import { compose } from 'redux';
import { connect } from 'react-redux';


const mapStateToProps = (state) => {
  return {
    loading: state.Common.loading,
    loggedIn: state.Auth.loggedIn,
    coverUploading: state.Common.coverUploading,
    otherUploading: state.Common.otherUploading,
  }
}

const mapDispatchToProps = dispatch => ({

});
class ImagesBox extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      selectedFile: null,
      croppedImage: null,

    }
  }
  componentDidUpdate = () => {
    if(this.state.editMode&&this.props.image_type!=this.props.type) {
      this.setState({editMode: false})
    }
  }


  handleEditMode = () => {
    this.setState({
      editMode: true
    });
    this.props.handleImageType(this.props.type)
  }


  handleApply = () => {

    if (this.props.required === true) {
      if (this.props.value === null) {
        this.props.onError(true, this.props.label + " can not be empty", 'error');
        return false;
      }

      if (this.props.value.length === 0) {
        this.props.onError(true, this.props.label + " can not be empty", 'error');
        return false;
      }
    }
    this.setState({ editMode: false });
    this.props.handleImageType("")
  }


  handleSelectFile = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({ selectedFile: reader.result, fileName: files[0].name }, () => console.log(this.state));
    };
    reader.readAsDataURL(files[0]);
  }

  _crop() {
    // image in dataUrl

    this.setState({
      croppedImage: this.refs.cropper.getCroppedCanvas().toDataURL('image/jpeg')
    });
  }

  onUpload = () => {
    this.props.onChange(this.state.croppedImage, this.state.fileName, this.props.type);
    this.setState({ selectedFile: null });
  }

  onSort = (sortedList) => {

    var sendArray = [];

    for (var x = 0; x < sortedList.length; x++) {

      sendArray.push({ iid: sortedList[x].iid, rank: sortedList[x].rank });
    }

    this.props.onSort(this.props.images[0].activity_id, sendArray);

  }

  render() {
    return <div>
      {this.state.editMode === false ? (<div className="supplier-block gallery-details">
        <a onClick={() => this.handleEditMode()} className="edit-block"></a>
        <div className="block-content">
          <div className="inner-block-content">
            <div className="one-full-col ">
              <div className="supply-col">
                <label>{this.props.label}</label>
                <div className="col-variable">
                  <div className="gallery-images">

                    {this.props.type === 'cover' ? (<div className="gallery-img"><img src={this.props.image + "?a=" + Math.ceil(Math.random() * 10)} /></div>) : null}

                    {this.props.type === 'other' ? (<div>
                      {this.props.images !== null ? (
                        <div>
                          {this.props.images.map((imgs) => {
                            return <div className="gallery-img g-other-images"><img src={imgs.url} /></div>;
                          })}
                        </div>
                      ) : null}
                    </div>) : null}


                    <div className="clear"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear"></div>
          </div>
        </div>

      </div>
      ) : (


        <div class="supplier-block gallery-details editable">
          <a href="#" class="close-block"></a>
          <div class="block-content">
            <div class="inner-block-content">
              <div class="one-full-col ">
                <div class="supply-col">
                  <div class="image-upload-widget">

                    <div style={{ fontWeight: 600, paddingLeft: 10, fontSize: 17 }}>

                      <tr>
                        <td></td>
                        <td><input
                          accept="image/*"
                          style={{ display: "none" }}
                          id="file-work-logo"
                          type="file"
                          onChange={this.handleSelectFile}
                        />
                        </td>
                      </tr>
                    </div>

                    {this.state.selectedFile !== null ? (
                      <div>
                        <Cropper className="cropper"
                          style={{ height: 400 }}
                          ref='cropper'
                          src={this.state.selectedFile}
                          viewMode={3}
                          // Cropper.js options
                          aspectRatio={this.props.ratio}
                          guides={false}
                          crop={this._crop.bind(this)} /> <br />
                        <Button component="span" onClick={() => this.onUpload()} variant="contained" color="default" >
                          Save &nbsp;
                        </Button> &nbsp;
                        <Button component="span" onClick={() => this.setState({ selectedFile: null })} variant="contained" color="default" >
                          Cancel
                        </Button>
                      </div>

                    ) : null}

                    {this.props[this.props.type + 'Uploading'] === true ? (<div style={{ textAlign: 'center' }}>
                      <CircularProgress style={{ color: '#000' }} /><br />
                      <p>Uploading image, please wait...</p>
                    </div>) : null}


                  </div>
                  <label>{this.props.info}</label>
                  <div class="col-variable">
                    <div class="gallery-images">



                      {this.props.type === 'cover' ? (<div className="gallery-img" ><img src={this.props.image + "?a=" + Math.ceil(Math.random() * 10)} /></div>) : null}

                      {this.props.type === 'other' ? (<div>
                        {this.props.images !== null ? (
                          <div>

                            <DragSortableList items={this.props.images.map((imgs) => {
                              return { iid: imgs.id, content: (<div className="gallery-img" style={{ width: '96%' }}><a style={{ cursor: 'pointer' }} onClick={() => this.props.onRemove(imgs.activity_id, imgs.id)} class="close-button"></a><img src={imgs.url} /></div>) };
                            })} onSort={this.onSort} dropBackTransitionDuration={0.3} type="grid" />

                            {/* {this.props.images.map((imgs)=>{
                                        return <div className="gallery-img"><a style={{cursor:'pointer'}} onClick={()=>this.props.onRemove(imgs.activity_id,imgs.id)}  class="close-button"></a><img src={imgs.url} /></div>;
                                })} */}
                          </div>
                        ) : null}
                      </div>) : null}
                      <div className="clear"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="clear"></div>
              <br />
              <div className="button-container">

                {this.props[this.props.type + 'Uploading'] === false ? (<label htmlFor="file-work-logo" >
                  <a style={{ cursor: 'pointer' }} className="button-apply">Upload Photo</a>
                </label>) : null}

                &nbsp;
                {this.props.showDone ? (<a onClick={() => this.handleApply()} className="button-apply">Done</a>) : null}
              </div>
            </div>
          </div>

        </div>)}
    </div>
  }
}
ImagesBox.defaultProps = {
  showDone: true
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(ImagesBox);