import React from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { withRouter } from 'react-router-dom'

import Emoji1 from '../activity/images/img-emoji.png';
import Emoji2 from '../activity/images/img-emoji.png';

import { compose } from 'redux';
import { connect } from 'react-redux';


class ActivityStatus extends React.Component {


  constructor(props) {
    super(props);

    this.state = {

    }
  }

  backToListing = () => {
    this.props.history.push('/Tours');
  }

  createNew = () => {
    this.props.history.push('/NewActivity');
  }

  render() {



    return (<div>
      {this.props.status === 0 ? (
        <div className="supplier-block message-details">

          <div className="block-content">
            <div className="inner-block-content">
              <div className="one-full-col">
                <div className="step-message">
                  <div className="step-img">
                    <img src={Emoji1} />
                    <div className="message-greet">WOW</div>
                  </div>

                  <div className="step-msg">
                    <h6>You have completed the product entering process. Please submit your product for the admin review. </h6>
                    <p>Admin will review your content, photos and make necessary adjustments before approving. If the photos are not appropriate admin will ask you to include different photos before the product goes live.    </p>
                    <a onClick={() => this.props.onSubmit()} style={{ cursor: 'pointer' }} className="button-orange btn-last-step">Submit Product </a>
                  </div>
                  <div className="clear"></div>
                </div>
              </div>
              <div className="clear"></div>
            </div>
          </div>

        </div>
      ) : null}

      {this.props.status === 3 ? (
        <div className="supplier-block message-details">
          <div className="block-content">
            <div className="inner-block-content">
              <div className="one-full-col">
                <div className="step-message">
                  <div className="step-img">
                    <img src={Emoji2} />
                    <div className="message-greet">Great !</div>
                  </div>

                  <div className="step-msg">
                    <h6>Thank you for submitting your product!  </h6>
                    <p>Your Product is being reviewed by the Admin. It will take 2-7 days for your product to go live. </p>
                    <a onClick={() => this.backToListing()} style={{ cursor: 'pointer' }} className="button-orange btn-last-step">Tours & Activities</a> &nbsp;
                    <a onClick={() => this.createNew()} style={{ cursor: 'pointer' }} className="button-orange btn-last-step">Add new</a>

                  </div>
                  <div className="clear"></div>
                </div>
              </div>
              <div className="clear"></div>
            </div>
          </div>
        </div>
      ) : null}


      {this.props.status === 1 ? (
        <div class="supplier-block message-details">
          <div class="block-content">
            <div class="inner-block-content">
              <div class="one-full-col">
                <div class="step-message">
                  <div class="step-img">
                    <img src={Emoji2} />
                    <div class="message-greet">Congratulations</div>
                  </div>
                  <div class="step-msg">
                    <h6>Your product is live. Customers can purchase your product on Aktivitar now. </h6>
                    <a href={this.props.full_seo_url} target="_blank" class="button-orange btn-last-step">Visit Product </a>
                  </div>
                  <div class="clear"></div>
                </div>

              </div>

              <div class="clear"></div>
            </div>

          </div>


        </div>
      ) : null}

    </div>)

  }
}

export default compose(withRouter)(ActivityStatus);