export default function reducer(state={
    list: null,
    activeTour: {
                    id:0,
                    title:"",
                    full_seo_url:"",
                    tour_type:0,
                    duration:0,
                    duration_value:0,
                    categories: [],
                    overview:"",
                    adults_age_from:null,
                    adults_age_to:null,
                    children_age_from:null,
                    children_age_to:null,
                    infant_age_from:null,
                    infant_age_to:null,
                    inclusion:[],
                    exclision:[],
                    know:[],
                    condtions:[],
                    contact_number:null,
                    images: new Array(),
                    itinery: new Array(),
                    pricing_for:null,
                    schedules : new Array(),
                    locationgroups:[],
                    locationgroupsLKR:[],
                    sale_type:null,
                    cutoff_time_duration:0,
                    cutoff_time_measure:0,
                    cutoff_time_related:0,
                    updated:false,
                    commission:0,
                    include_adults:0,
                    include_children:0,
                    include_infants:0,
                    cover_image:"",
                    status:0,
                    know_policy:"",
                    cancel_policy:"",
                    cancel_custom:"",
                    terms_accepted:0,
                    remarks:[],
                    local_inclustions:[],
                    local_conditions:"",
                    destination_location:0,
                    show_help:false,
                },


 knowPolicies:[],
 cancelPolicies:[]               
    
},action){
switch(action.type){
    case "STORE_ACTIVITIES":{
        return{...state,list:action.list}
    }
    case "STORE_ACTIVE_ACTIVITY":{
        return{...state,
                    activeTour:{
                                    id:action.activity.id,
                                    title:action.activity.title,
                                    full_seo_url:action.activity.full_seo_url,
                                    tour_type:action.activity.tour_type,
                                    duration:action.activity.duration,
                                    duration_value:action.activity.duration_value,
                                    categories: action.activity.categories,
                                    overview:action.activity.overview,
                                    adults_age_from:action.activity.adults_age_from,
                                    adults_age_to:action.activity.adults_age_to,
                                    children_age_from:action.activity.children_age_from,
                                    children_age_to:action.activity.children_age_to,
                                    infant_age_from:action.activity.infant_age_from,
                                    infant_age_to:action.activity.infant_age_to,
                                    inclusion:action.activity.inclusion,
                                    exclision:action.activity.exclision,
                                    know:action.activity.know,
                                    condtions:action.activity.condtions,
                                    contact_number:action.activity.contact_number,
                                    images: action.activity.images,
                                    itinery: action.activity.itinery,
                                    pricing_for:action.activity.pricing_for,
                                    schedules:action.activity.schedules,
                                    locationgroups:action.activity.locationgroups,
                                    locationgroupsLKR:action.activity.locationgroupsLKR,
                                    sale_type:action.activity.sale_type,
                                    cutoff_time_duration:action.activity.cutoff_time_duration,
                                    cutoff_time_measure:action.activity.cutoff_time_measure,
                                    cutoff_time_related:action.activity.cutoff_time_related,
                                    commission:action.activity.commission,
                                    include_adults:action.activity.include_adults,
                                    include_children:action.activity.include_children,
                                    include_infants:action.activity.include_infants,
                                    cover_image:action.activity.updated_cover_image,
                                    know_policy:action.activity.know_policy,
                                    cancel_policy:action.activity.cancel_policy,
                                    cancel_custom:action.activity.cancel_custom,
                                    status:action.activity.status,
                                    terms_accepted:action.activity.terms_accepted,
                                    remarks:action.activity.remarks,
                                    local_inclustions:action.activity.local_inclustions,
                                    local_conditions:action.activity.local_conditions,
                                    destination_location:action.activity.destination_location !== null ? action.activity.destination_location:0,
                                    updated:true,
                                }
              }
    }
    case "LOCK_ACTIVE_ACTIVITY":{
        return{...state,
                    activeTour:{                                    
                                    updated:false,
                                }
              }
    }    
    case "LOADIND_END":{ 
        return{...state,loading:false}
    }
    case "UPDATE_TOUR":{
        return {...state,activeTour:action.tour}
    }  
    
    case "SHOW_HELP":{
        return {...state,show_help:action.show}
    }
    case "STORE_POLICIES":{
        return {...state,[action.policyType]:action.list}
    }  
}
return state
}