import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Schedule from './Schedule';
import ScheduleLocal from './SheduleLocal';

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});


class SchedulePanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      rateChange: "INTERNATIONAL"
    }
  }



  render() {
    console.log(this.state.rateChange);
    console.log(this.props);
    const { classes } = this.props;
    return (
      <div>

        <div>
          <div className="button-container">


            <div>
              <div style={{ float: 'left' }}><h2>Rates & Availability</h2> </div>
              <div style={{ clear: 'both' }} ></div>
            </div>



          </div>

          <div className={classes.root}>

            <div style={{ marginTop: 15 }} />
            {this.props.market !== "LOCAl" ? <div>
              {this.props.locationgroups.map((g, i) => {
                return <Schedule addLocation={this.props.addLocation}
                  changeScheduleTitle={this.props.changeScheduleTitle}
                  changeDate={this.props.changeDate}
                  addPickup={this.props.addPickup}
                  addPriceGroup={this.props.addPriceGroup}
                  updatePricGroup={this.props.updatePricGroup}
                  removePricGroup={this.props.removePricGroup}
                  removeLocation={this.props.removeLocation}
                  removeSchedule={this.props.removeSchedule}
                  activity={this.props.activity}
                  index={i}
                  group={g}
                  editSchedule={this.props.editMode}
                  updatePickupLocation={this.props.updatePickupLocation}
                  addGroupPickupLocation={this.props.addGroupPickupLocation}
                  addSeason={this.props.addSeason}
                  deleteSeason={this.props.deleteSeason}
                  deleteLocationGroup={this.props.deleteLocationGroup}
                  deleteGroupPickupLocation={this.props.deleteGroupPickupLocation}
                  updateGroupPickupLocation={this.props.updateGroupPickupLocation}
                  rateChange={this.props.market}
                  locationgroups={this.props.locationgroups}
                />;
              })}</div>
              : <div>
                {this.props.locationgroupsLKR.map((g, i) => {
                  return <Schedule addLocation={this.props.addLocationLocal}
                    changeScheduleTitle={this.props.changeScheduleTitleLocal}
                    changeDate={this.props.changeDateLocal}
                    addPickup={this.props.addPickupLocationLocal}
                    addPriceGroup={this.props.addPricGroupLocal}
                    updatePricGroup={this.props.updatePricGroupLocal}
                    removePricGroup={this.props.removePricGroupLocal}
                    removeLocation={this.props.removeLocationLocal}
                    removeSchedule={this.props.removeScheduleLocal}
                    activity={this.props.activity}
                    index={i}
                    group={g}
                    editSchedule={this.props.editMode}
                    updatePickupLocation={this.props.updatePickupLocationLocal}
                    addGroupPickupLocation={this.props.addGroupPickupLocationLocal}
                    addSeason={this.props.addSeasonLocal}
                    deleteSeason={this.props.deleteSeasonLocal}
                    deleteLocationGroup={this.props.deleteLocationGroupLocal}
                    deleteGroupPickupLocation={this.props.deleteGroupPickupLocationLocal}
                    updateGroupPickupLocation={this.props.updateGroupPickupLocationLocal}
                    rateChange={this.props.market}
                    locationgroups={this.props.locationgroupsLKR}
                  />;
                })}</div>}


            {this.props.market === "INTERNATIONAL" ?
              <div onClick={this.props.addLocationGroup} className="group-add-button3">
                ADD NEW LOCATION GROUP
              </div> :

              <div onClick={this.props.addLocationGroupLocal} className="group-add-button3">
                ADD NEW LOCATION GROUP
              </div>
            }
          </div>

          <div className="button-container" style={{ marginTop: 15 }}>
            {this.props.shedules.length > 0 ? (
              <Fragment>
                <div>
                  <div style={{ float: 'left' }}></div>
                  <div style={{ float: 'right' }}>
                    <Button variant="text" onClick={this.props.addLocationGroup} >Add new</Button>
                  </div>
                  <div style={{ clear: 'both' }} ></div>
                </div>
              </Fragment>
            ) : null}

          </div>

        </div>
      </div>
    );
  }
}

SchedulePanel.defaultProps = {
  shedules: new Array(),
  locationgroups: new Array(),
  editMode: false,
  market: "INTERNATIONAL"
}


SchedulePanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SchedulePanel);
