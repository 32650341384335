import React, { Fragment } from 'react';
import TagInput from '../Components/TagInput';
import TimeField from 'react-simple-timefield';
import { compose } from 'redux';
import { connect } from 'react-redux';

const getLocation = (locations, locationgroups, item) => {
    var retLocation = locations
    for (let i = 0; i < locations.length; i++) { // locations[i].value
        for (let x = 0; x < locationgroups.length; x++) {
            var pickUpLocations = locationgroups[x].pickUpLocations
            for (let y = 0; y < pickUpLocations.length; y++) {
                var location = pickUpLocations[y].location
                if (location.value == locations[i].value && locations[i].value != item.value) {
                    retLocation.splice(i, 1)
                }
            }
        }
    }
    return retLocation
}

class LocationRow extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            editMode: false,
        }
    }


    handleEditMode = () => {
        this.setState({
            editMode: true
        });
    }


    handleApply = () => {

        if (this.props.required === true) {
            if (this.props.value === null) {
                this.props.onError(true, this.props.label + " can not be empty", 'error');
                return false;
            }

            if (this.props.value.length === 0) {
                this.props.onError(true, this.props.label + " can not be empty", 'error');
                return false;
            }
        }


        this.setState({
            editMode: false
        });
    }

    render() {
        // {console.log(this.props.locations,"locRow")}
        return <Fragment>
            {this.state.editMode === false ? (<tr className={this.props.pIndex % 2 !== 0 ? 'odd' : "even"}>
                <td>{this.props.item.location !== null ? this.props.item.location.label : null}</td>
                <td>{this.props.item.pickup_time}</td>
                <td>{this.props.item.description}</td>
                <td class="row-controls">
                    <a style={{ cursor: 'pointer' }} onClick={() => this.handleEditMode()} class="edit-row"></a>
                    <a style={{ cursor: 'pointer' }} onClick={() => this.props.removeLocation(this.props.sIndex, this.props.pIndex)} class="delete-row"></a>
                </td>
            </tr>
            ) : (<tr className="add-option">
                <td>
                    <TagInput
                        selectedLocations={this.props.item.location}
                        options={getLocation(this.props.locations, this.props.locationgroups, this.props.item.location)}
                        onPickupChange={(value) => { this.props.updatePickupLocation(this.props.sIndex, this.props.pIndex, 'location', value) }} />
                </td>
                <td>
                    <TimeField
                        value={this.props.item.pickup_time}                     // {String}   required, format '00:00' or '00:00:00'
                        onChange={(value) => { this.props.updatePickupLocation(this.props.sIndex, this.props.pIndex, 'pickup_time', value) }}      // {Function} required                    
                        colon=":"                       // {String}   default: ":"
                        className="add-more"
                        style={{ width: 70, textAlign: 'center' }}                      // {Boolean}  default: false
                    />
                </td>
                {/* <td><input type="text" value={this.props.item.pickup_time} className="add-more" onChange={(e)=>{this.props.updatePickupLocation(this.props.sIndex,this.props.lIndex,this.props.pIndex,'pickup_time',e.target.value)}} /></td> */}
                <td><input type="text" value={this.props.item.description} className="add-more" onChange={(e) => { this.props.updatePickupLocation(this.props.sIndex, this.props.pIndex, 'description', e.target.value) }} /></td>
                <td><a onClick={() => this.setState({ editMode: false })} className="add-more-button"></a></td>
            </tr>)}
        </Fragment>
    }
}

const mapStateToProps = (state) => {
    return {
      locations: state.Locations.locations,
    }
  }
  
const mapDispatchToProps = dispatch => ({

});

export default compose(connect(mapStateToProps, mapDispatchToProps))(LocationRow) ;